@use "./../../scss/" as *;

.bg-1 {
  background-color: $bg-item-1;
}
.bg-2 {
  background-color: $bg-item-2;
}
.bg-3 {
  background-color: $bg-item-3;
}
.item {
  width: 170px;
  height: 170px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.section-about .item {
  width: 270px;
  height: 320px;
  border-radius: 20px;

  &.big {
    width: 420px;
    height: 500px;

    @include mobile {
      width: 320px;
    }
  }
  &.small {
    width: 133px;
    height: 158px;
    margin-top: 43px;
    margin-bottom: 48px !important;

    @include mobile {
      margin-left: auto;
      margin-right: auto;
    }
  }

  img {
    animation: move 3s infinite linear;
  }
}

.portfolio .item {
  width: 360px;
  height: 280px;

  @include desktop-1900 {
    width: 100%;
  }
}

@keyframes move {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.hero-slider.style-2 {
  overflow: hidden;
  position: relative;
  .shape {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    @include mobile {
      display: none;
    }
  }
  .item {
    position: absolute;
    z-index: -1;
    animation: up-down linear 4s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    -webkit-animation: up-down linear 4s;
    -webkit-animation-iteration-count: infinite;

    @keyframes up-down {
      0% {
        transform: translate(1px, 20px);
      }
      24% {
        transform: translate(1px, 30px);
      }
      50% {
        transform: translate(1px, 12px);
      }
      74% {
        transform: translate(1px, 22px);
      }
      100% {
        transform: translate(1px, 22px);
      }
    }

    &:nth-child(1) {
      @include size(140px, 140px);
      left: 8%;
      top: 70%;
    }

    &:nth-child(2) {
      @include size(140px, 140px);
      right: 8%;
      top: 70%;
    }

    @include tablet {
      &:nth-child(1) {
        left: 5%;
        top: 80%;
      }
      &:nth-child(2) {
        right: 5%;
        top: 80%;
      }
    }
  }
}
